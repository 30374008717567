const allDirectors = {
  '70S Altman': { imdb: 'nm0000265' },
  'Alex Proyas': { imdb: 'nm0001639' },
  'Alfonso Cuaron': { imdb: 'nm0190859' },
  'Amy Heckerling': { imdb: 'nm0002132' },
  'Anthony Minghella': { imdb: 'nm0005237' },
  'Barry Sonnenfeld': { imdb: 'nm0001756' },
  'Bill Condon': { imdb: 'nm0174374' },
  'Blake Edwards': { imdb: 'nm0001175' },
  'Bob Fosse': { imdb: 'nm0002080' },
  'Bong Joon-Ho': { imdb: 'nm0094435' },
  'Buster Keaton': { imdb: 'nm0000036' },
  'Chris Columbus': { imdb: 'nm0001060' },
  'Coen Brothers': { imdb: 'nm0001054' },
  Cronenberg: { imdb: 'nm0000343' },
  'Danny Boyle': { imdb: 'nm0000965' },
  'David Lean': { imdb: 'nm0000180' },
  'Dennis Dugan': { imdb: 'nm0240797' },
  'Elaine May': { imdb: 'nm0561938' },
  'F. Gary Gray': { imdb: 'nm0336620' },
  'Francis F. Coppola': { imdb: 'nm0000338' },
  'George Miller': { imdb: 'nm0004306' },
  'Gore Verbinski': { imdb: 'nm0893659' },
  'Guillermo Del Toro': { imdb: 'nm0868219' },
  'Gus Van Sant': { imdb: 'nm0001814' },
  'Guy Ritche': { imdb: 'nm0005363' },
  'Joe Dante': { imdb: 'nm0001102' },
  'John Carpenter': { imdb: 'nm0000118' },
  'John McTiernan': { imdb: 'nm0001532' },
  'John Singleton': { imdb: 'nm0005436' },
  'Jonathan Demme': { imdb: 'nm0001129' },
  'Kevin Costner': { imdb: 'nm0000126' },
  'Martin Brest': { imdb: 'nm0000976' },
  'Martin Scorsese': { imdb: 'nm0000217' },
  'Mel Brooks': { imdb: 'nm0000316' },
  'Michael Bay': { imdb: 'nm0000881' },
  'Michael Cimino': { imdb: 'nm0001047' },
  'Mike Nichols': { imdb: 'nm0001566' },
  'Milos Forman': { imdb: 'nm0001232' },
  'Nora Ephron': { imdb: 'nm0001188' },
  'P.T. Anderson': { imdb: 'nm0000759' },
  'P.W.S. Anderson': { imdb: 'nm0027271' },
  'Penny Marshall': { imdb: 'nm0001508' },
  'Peter Farrelly': { imdb: 'nm0268380' },
  'Peter Jackson': { imdb: 'nm0001392' },
  'Peter Weir': { imdb: 'nm0001837' },
  'Preston Sturges': { imdb: 'nm0002545' },
  Prince: { imdb: 'nm0002239' },
  'Robert Zemeckis': { imdb: 'nm0000709' },
  'Sam Raimi': { imdb: 'nm0000600' },
  'Sylvester Stallone': { imdb: 'nm0000230' },
  'Terrence Malick': { imdb: 'nm0000517' },
  'Tom Green': { imdb: 'nm0338381' },
  'Tom Hooper': { imdb: 'nm0393799' },
  'Tony Scott': { imdb: 'nm0001716' },
  'Warren Beatty': { imdb: 'nm0000886' },
  'William Friedkin': { imdb: 'nm0001243' },
  'John Cassavetes': { imdb: 'nm0001023' },
  'Lynne Ramsay': { imdb: 'nm0708903' },
  'David S. Ward': { imdb: 'nm0911486' },
  'Mike Judge': { imdb: 'nm0431918' },
  'Danny Devito': { imdb: 'nm0000362' },
  'Harmony Korine': { imdb: 'nm0005101' },
  'Ernest Dickerson': { imdb: 'nm0225416' },
  'Spike Jonze': { imdb: 'nm0005069' },
  'Penelope Spheeris': { imdb: 'nm0790715' },
  'Karyn Kusama': { imdb: 'nm0476201' },
  'Jane Campion': { imdb: 'nm0001005' },
  'The Archers': {
    imdb: 'https://en.wikipedia.org/wiki/Powell_and_Pressburger#:~:text=The%20British%20film%2Dmaking%20partnership,in%20the%201940s%20and%201950s.',
  }, // TODO:nm0696247  nm0003836
  'Wong Kar Wai': { imdb: 'nm0939182' },
  'Steve McQueen': { imdb: 'nm2588606' },
  'Mike Leigh': { imdb: 'nm0005139' },
  'Joe Johnston': { imdb: 'nm0002653' },
  'Jacques Tati': { imdb: 'nm0004244' },
  'Wolfgang Petersen': { imdb: 'nm0000583' },
  'Oliver Stone': { imdb: 'nm0000231' },
  'Jackie Chan': { imdb: 'nm0000329' },
  'Kelly Reichardt': { imdb: 'nm0716980' },
  'Pablo Larrain': { imdb: 'nm1883257' },
  'Park Chan-wook': { imdb: 'nm0661791' },
  'Sergio Leone': { imdb: 'nm0001466' },
  'Mira Nair': { imdb: 'nm0619762' },
  'Francois Truffaut': { imdb: 'nm0000076' },
  'Jia Zhangke': { imdb: 'nm0422605' },
  'Ousmane Sembene': { imdb: 'nm0783733' },
  'Satoshi Kon': { imdb: 'nm0464804' },
  'Lars Von Trier': { imdb: 'nm0001885' },
  'Baz Luhrmann': { imdb: 'nm0525303' },
  'Lucrecia Martel': { imdb: 'nm0551506' },
  'Stephen Chow': { imdb: 'nm0159507' },
  'Abbas Kiarostami': { imdb: 'nm0452102' },
  'Claire Denis': { imdb: 'nm0219136' },
  'Pedro Almodovar': { imdb: 'nm0000264' },
  'Agnes Varda': { imdb: 'nm0889513' },

  'Steven Soderbergh': { imdb: 'nm0001752' },
  'Barry Levinson': { imdb: 'nm0001469' },
  'James Gray': { imdb: 'nm0336695' },
  'Isao Takahata': { imdb: 'nm0847223' },
  'Edgar Wright': { imdb: 'nm0942367' },
  'Leos Carax': { imdb: 'nm0136021' },
  'James Wan': { imdb: 'nm1490123' },
  'Nicole Holofcener': { imdb: 'nm0392237' },
  'David Lynch': { imdb: 'nm0000186' },
  'Bill Duke': { imdb: 'nm0004886' },
  'Richard Kelly': { imdb: 'nm0446819' },
  'Harold Ramis': { imdb: 'nm0000601' },
  'Denis Villeneuve': { imdb: 'nm0898288' },
  'Curtis Hanson': { imdb: 'nm0000436' },
  'Wes Craven': { imdb: 'nm0000127' },
  'Jonathan Glazer': { imdb: 'nm0322242' },
  'Ridley Scott': { imdb: 'nm0000631' },
  'Alan Pakula': { imdb: 'nm0001587' },
  'Andrew Stanton': { imdb: 'nm0004056' },
  'Jim Jarmusch': { imdb: 'nm0000464' },
  'Todd Haynes': { imdb: 'nm0001331' },
  'James Mangold': { imdb: 'nm0003506' },
  'Judd Apatow': { imdb: 'nm0031976' },
  'Alex Garland': { imdb: 'nm0307497' },
  'Spike Lee': { imdb: 'nm0000490' },
  'Joe Wright': { imdb: 'nm0942504' },
  'John Hughes': { imdb: 'nm0400697' },
  'Céline Sciamma': { imdb: 'nm1780037' },
  'Richard Linklater': { imdb: 'nm0000500' },
  'Joan Micklin Silver': { imdb: 'nm0798717' },
  'Luca Guadagnino': { imdb: 'nm0345174' },
  'Jay Ward': { imdb: 'nm0911599' },
  'Richard Curtis': { imdb: 'nm0193485' },
};

const allSeries = {
  Alien: { imdb: '' },
  'Anotoine Doniel': { imdb: 'https://en.wikipedia.org/wiki/Antoine_Doinel' },
  'Austin Powers': { imdb: '' },
  'Before Trilogy': { imdb: 'https://en.wikipedia.org/wiki/Before_trilogy' },
  'Fast & Furious': { imdb: '' },
  Frankenstein: { imdb: '' },
  Halloween: { imdb: '' },
  'Harry Potter': { imdb: '' },
  'Indiana Jones': { imdb: '' },
  'James Bond: Connery': {
    imdb: 'https://jamesbond.fandom.com/wiki/James_Bond_(Sean_Connery)',
  },
  'John wick': { imdb: '' },
  'Jurassic Park': { imdb: '' },
  'Living Dead': { imdb: '' },
  'Mission: Impossible': { imdb: '' },
  'Moral Tales': { imdb: '' },
  'Nightmare on Elm St': { imdb: '' },
  'Planet of the Apes: Originals': { imdb: '' },
  'Police Story': {
    imdb: 'https://en.wikipedia.org/wiki/Police_Story_(film_series)',
  },
  'Qatsi Trilogy': { imdb: 'https://en.wikipedia.org/wiki/Qatsi_trilogy' },
  'Resident Evil': { imdb: '' },
  Robocop: { imdb: 'https://en.wikipedia.org/wiki/RoboCop_(franchise)' },
  Shrek: { imdb: '' },
  'Star Trek: TOS': { imdb: '' },
  Superman: { imdb: '' },
  Terminator: { imdb: '' },
  'The Apu Trilogy': { imdb: 'https://en.wikipedia.org/wiki/The_Apu_Trilogy' },
  'The Exorcist': { imdb: '' },
  'The Matrix': { imdb: '' },
  'The Muppets': { imdb: '' },
  'Three Colours': {
    imdb: 'https://en.wikipedia.org/wiki/Three_Colours_trilogy',
  },
  Twilight: { imdb: '' },
  Wolverine: { imdb: '' },

  TMNT: { imdb: '' },
  'Hunger Games': { imdb: '' },
  'Freddy/Elm St.': { imdb: '' },
  'Final Destination': { imdb: '' },
  'Analyze This': { imdb: 'tt0122933' },
  Vacation: { imdb: 'tt1524930' },
  'H.S. Musical': { imdb: '' },
  Rugrats: { imdb: '' },
  'Men in Black': { imdb: '' },
  'Toxic Avengers': { imdb: 'tt0090190' },
  Chucky: { imdb: '' },
  Lego: { imdb: '' },
  'The Mummy': { imdb: '' },
  'Bill & Ted': { imdb: '' },
  Barbershop: { imdb: '' },

  Bourne: { imdb: '' },
  'Monty Python': { imdb: 'https://en.wikipedia.org/wiki/Monty_Python' },
  'Cheech & Chong': { imdb: 'https://en.wikipedia.org/wiki/Cheech_%26_Chong' },

  Riddick: { imdb: '' },
  'The Mighty Ducks': { imdb: '' },
  'The Karate Kid': { imdb: '' },
  Rocky: { imdb: '' },
  'Dirty Harry': {
    imdb: 'https://en.wikipedia.org/wiki/Dirty_Harry_(film_series)',
  },

  Saw: { imdb: '' },
  'Blair Witch': { imdb: '' },

  'Moore Bond': {
    imdb: 'https://jamesbond.fandom.com/wiki/James_Bond_(Roger_Moore)',
  },
  'Bruce Lee': { imdb: 'nm0000045' },
  Shaft: { imdb: 'https://en.wikipedia.org/wiki/Shaft_(franchise)' },
  'Dollars Trilogy': { imdb: 'https://en.wikipedia.org/wiki/Dollars_Trilogy' },
  'The Beatles': { imdb: 'https://en.wikipedia.org/wiki/The_Beatles_in_film' },
  'Marx Brothers': { imdb: 'nm2580347' },
  Airport: { imdb: 'https://en.wikipedia.org/wiki/Airport_(film_series)' },
  'Naked Gun': {
    imdb: 'https://en.wikipedia.org/wiki/The_Naked_Gun_(franchise)',
  },
  'Oh, God!': { imdb: 'https://en.wikipedia.org/wiki/Oh,_God!_(film)' },
  'Day & Hudson': { imdb: '' },
  'Classic Mummy': { imdb: '' },
  Hellraiser: { imdb: '' },
  Psycho: { imdb: '' },
  Jaws: { imdb: '' },
  'The Fly': { imdb: '' },
  'Lethal Weapon': { imdb: '' },
  'Gold Diggers': { imdb: '' },
  Oz: { imdb: '' },

  'Charlie Chaplin': { imdb: 'nm0000122' },
  'F. W. Murnau': { imdb: 'nm0003638' },
  'Carl Th. Dreyer': { imdb: 'nm0003433' },
  'Dorothy Arzner': { imdb: 'nm0002188' },
  'Hitchcock I (1929 - 1941)': { imdb: 'nm0000033' },
  'Fritz Lang': { imdb: 'nm0000485' },
  'Sergei Eisenstein': { imdb: 'nm0001178' },
  'Stanley Kubrick': { imdb: 'nm0000040' },
  'Luchino Visconti': { imdb: 'nm0899581' },
  'Andrei Tarkovsky': { imdb: 'nm0001789' },
  'Melvin Van Peebles': { imdb: 'nm0887708' },
  'Hitchcock II (1942 - 1976)': { imdb: 'nm0000033' },
  'Jean Cocteau': { imdb: 'nm0168413' },
  'Ernst Lubitsch': { imdb: 'nm0523932' },
  'Ida Lupino': { imdb: 'nm0526946' },
  'Orson Welles': { imdb: 'nm0000080' },
  'Early Spielberg': { imdb: 'nm0000229' },
  'Dutch Verhoeven': { imdb: 'nm0000682' },
  'Barbra Streisand': { imdb: 'nm0000659' },
  'Albert Brooks': { imdb: 'nm0000983' },
  'Don Bluth': { imdb: 'nm0089940' },
  'Edward Yang': { imdb: 'nm0945981' },
  'Magic Mike': { imdb: '' },
  'Philip K. Dick': {
    imdb: 'https://en.wikipedia.org/wiki/List_of_adaptations_of_works_by_Philip_K._Dick',
  },
  'Godzilla Millennium Series': {
    imdb: 'https://godzilla.fandom.com/wiki/Millennium_series#:~:text=The%20Millennium%20series%20(%E3%83%9F%E3%83%AC%E3%83%8B%E3%82%A2%E3%83%A0%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA,released%20from%201999%20to%202004.',
  },
  "Ocean's": { imdb: 'https://en.wikipedia.org/wiki/Ocean%27s_(film_series)' },
  'Universal Soldier': {
    imdb: 'https://en.wikipedia.org/wiki/Universal_Soldier_(film_series)',
  },
  Purge: { imdb: 'https://en.wikipedia.org/wiki/The_Purge' },
  'The Ring': { imdb: 'https://en.wikipedia.org/wiki/The_Ring_(franchise)' },
  'Jelly Trilogy': { imdb: '' },
  'Bogie & Bacall': { imdb: '' },
  'John Grisham': { imdb: 'https://www.imdb.com/list/ls003773787/' },
  'Elmore Leonard': {
    imdb: 'https://en.wikipedia.org/wiki/Category:Films_based_on_works_by_Elmore_Leonard',
  },
  SNL: {
    imdb: 'https://en.wikipedia.org/wiki/List_of_Saturday_Night_Live_feature_films',
  },
  'Teen Shakespeare': { imdb: '' },
  'Star Trek: Next Gen': { imdb: '' },
  'Mortal Kombat': {
    imdb: 'https://en.wikipedia.org/wiki/Mortal_Kombat_(film_series)',
  },
  Asterisk: { imdb: 'https://en.wikipedia.org/wiki/List_of_Asterix_films' },
  Cars: { imdb: 'https://en.wikipedia.org/wiki/Cars_(franchise)' },
  Arthur: { imdb: '' },
  Scream: { imdb: 'https://en.wikipedia.org/wiki/Scream_(franchise)' },
  'Liam neeson/Collet-serra': { imdb: '' },

  'My Big Fat Greek Wedding': {
    imdb: 'https://en.wikipedia.org/wiki/My_Big_Fat_Greek_Wedding_(franchise)',
  },
  'The Addams Family': {
    imdb: 'https://en.wikipedia.org/wiki/The_Addams_Family',
  },
  Jumanji: { imdb: 'https://en.wikipedia.org/wiki/Jumanji_(franchise)' },
  'Bridget Jones': {
    imdb: 'https://en.wikipedia.org/wiki/Bridget_Jones_(film_series)',
  },
  'The Equalizer': { imdb: 'https://en.wikipedia.org/wiki/The_Equalizer' },
  'Body Snatchers': { imdb: '' },
  Rambo: { imdb: 'https://en.wikipedia.org/wiki/Rambo_(franchise)' },
  Ernest: { imdb: 'https://www.imdb.com/list/ls007691427' },
  'Pauly Shore': { imdb: 'nm0001736' },
  'Jack Ryan': { imdb: '' },
  'Robert Langdon': { imdb: 'https://en.wikipedia.org/wiki/Robert_Langdon' },
  'King Arthurs': { imdb: '' },
  'Robin Hoods': { imdb: '' },
  'Clive Barker': { imdb: '' },
  Cube: { imdb: '' },
  'Spy Kids': { imdb: '' },
  'Mexico Trilogy': { imdb: '' },
  'Happy Madison': { imdb: '' },
  'American Pie': { imdb: '' },
  'Lara Croft': { imdb: '' },
  Draculas: { imdb: '' },
  Poirot: { imdb: '' },
  'OZ/Henson': { imdb: '' }, // frank oz, jim henson.
  'Tabletop Games': { imdb: '' },
  'Alvin & The Chipmunks': { imdb: '' },
  Conan: { imdb: '' },
  Highlander: { imdb: '' },
  'Stand-up Animation': { imdb: '' },
  'Major League': { imdb: '' },
  'Pulp Heroes': { imdb: '' },
};

export const allOptions = {
  ...allDirectors,
  ...allSeries,
};

export type Options = keyof typeof allOptions | '';
